$('#forgot-pass-modal').on('click', function() {
    var email = $('#login-email').val() || $('#login-email-modal').val();

    if(email !== '') {
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: '/api/forgot_password.json',
            data: {
                email:email
            },
            success: function(msg){
                if ( msg.error && msg.error.key ) {
                    swal(msg.error.message);
                }
                else {
                    swal('Password reset instructions have been sent to your email address!');
                }
            },
            error: function() {
                swal('Please check your email address and try again.');
            }
        });
    } else {
        swal('Please Enter Email Address!');
    }
});